$(function(){

    $('.nav-toggler').on('click', function(){

        $('.nav').show();

    });

    $('.overlay').on('click', function(){

        $('.nav').hide();

    });

    $('.nav-menu__item').on('click', function(){
        if( $(window).width() < 1024 ) {
            $('.nav').hide();
        }
    });

    $('.references-slider').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: false
    });

    $('.accordion-tab').on('click', function(){

        $(this).next().slideToggle('slow');
        $(this).toggleClass('accordion-tab-open');

    });

    $('.home a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        event.preventDefault();
        if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
        && 
        location.hostname == this.hostname
        ) {
        var target = $(this.hash);
        h = 0;
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        console.log(target);
            if (target.length) {
                event.preventDefault();
                $('html, body').animate({
                scrollTop: target.offset().top
                }, 1000, function() {
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) {
                    return false;
                } else {
                    $target.attr('tabindex','-1');
                    $target.focus();
                };
                });
            }
        }
    });

    var myLazyLoad = new LazyLoad({
        elements_selector: ".lazy"
    });

    var mymap = L.map('mapid').setView([51.719724, 16.613797], 15);

    L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox.streets',
        accessToken: 'pk.eyJ1IjoibHdyb25za2kiLCJhIjoiY2pxcWNuM3VzMDFqaDN4bGoyZDVraGk0NSJ9.jgy8YnoUjBIMRxxpevXKkA'
    }).addTo(mymap);

    var markerCustom = L.icon({
        iconUrl: 'wp-content/themes/tanczacewinogrona/dist/images/customMarker.png',

        iconSize:     [48, 48], // size of the icon
        iconAnchor:   [24, 48], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -48] // point from which the popup should open relative to the iconAnchor
    });

    L.marker([51.719724, 16.613797], {icon: markerCustom}).addTo(mymap)
        .bindPopup('Poradnia żywieniowa - Tańczące Winogrona');

    mymap.scrollWheelZoom.disable()

});